*{
    box-sizing: border-box;
    margin:0 ;
    padding:0 ;
    
}

nav{
    position:relative;
    top:0;
    width: 100%;
    z-index:10;
    height: 80px;
    line-height: 50px;
   
   
    
}

.desktop-item:hover .icon-rotate svg {
    transition: 0.2s;
    transform:rotate(180deg) !important;
    -webkit-transform:rotate(180deg) !important;
    -ms-transform:rotate(180deg) !important;
    
}



nav .navbar{
    position:relative;
    padding: 0 30px;
    margin:auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.nav-links{
    display: flex;
    
    
}
.brand-logo img{
    height:90px;
}

.nav-links li{
    list-style: none; 
    
}


.nav-links li .nav-link{
    color:white;
    text-decoration: none;
    font-size: 17px;
    font-weight: 500px;
    padding: 9px 15px;
    transition:0.3s ease;
    text-transform: uppercase;
}

.nav-links li .nav-link:hover{
    font-weight: bold;    
}

.nav-link{
    font-size: 15px !important;
}

.know-more{
    background-color: #08baf5;
    border-radius:10px;
    border:none;
    padding:0 15px;
}

a.nav-link:active{
    color: #08baf5 !important;
}

a.mega-link:active{
    color: #08baf5 !important;
}

.mega-container {
    position: absolute;
    top:70px;
    width:60%;
    right: 0;
    padding: 0 35px;
    opacity:0;
    visibility: hidden;
    transition: 0.3s ease;
}

.mega-container .mega-content {
    background-color: #010528 ;
    /* background-color: #000426 ; */
    color:white;
    padding: 25px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;

}

.mega-left {
    width: 40%;
}

.mega-right {
    width: 60%;
}

.mega-content .row{
    /* width: calc(25% - 30px); */
    line-height: 45px;
}


.header-special{
    font-size: 35px;
    font-weight: bold;
}

.mega-links{
    font-size: 13px;
}

.mega-links p{
    line-height: normal;
    
}

.mega-special-container p{
    font-size: 16px;
}

.mega-link{
    text-decoration: none;
    color:white;
    border-bottom: 0.5px solid white;
    padding-bottom: 2%;
}


.mega-link:hover{
   border-bottom:2px solid #ffffff;
   background: rgba(0, 4, 38, .4);
   
}

.mega-content .row header {
    font-weight: bold;
}


.row .mega-links li .nav-link{
    padding: 0 20px;
    font-size: 17px;
    display: block;

}

/* Display mega menu on hover */
.nav-links li:hover .mega-container{
    top: 70px;
    opacity: 1;
    visibility: visible;
    transition: 0.3s ease;
}

.nav-links .mobile-item{
    display:none;
} 


.navbar .btn{
    color:#fff;
    font-size: 22px;
    cursor:pointer;
    display: none;
    right: 27px;
    top: 10px;
}


.navbar .btn.cancel-btn{
    position:absolute;  
    font-size: 25px;
    cursor:pointer;
}


.menu-btn svg{
    color:#fff;
    border:none !important;
}


.menu-btn svg:active{
    color:#fff;
    border:none !important;
}


@media screen and (max-width: 970px){
     /* To remove the white space at the top */
     nav {
        margin-top:-34px !important;
       
        
    }
    nav .navbar{
        padding: 40px 30px 5px 30px !important;
        background-color: #010528 ;
        
    }

    /* Media Query begins proper */

    .navbar .nav-links{
        position:fixed;
        height: 100vh;
        width: 100%;
        max-width: 350px;
        background-color:rgba(1, 5, 40, 0.9);
        display: block;
        top:0;
        left:-100%;
        overflow-y: auto;
        line-height: 50px;
        padding:50px 10px;
        transition: 0.3s ease;

    }
    

    .nav-links li{
        margin: 15px 10px;

    }

    .nav-links li a{
        padding:0 20px;
        display:block;
        font-size: 14px;
       
    }

    .brand-logo img{
        height:60px;
    }

    .nav-links .mega-container {
        position: static;
        top:70px;
        width:100%;
        opacity:1;
        visibility: visible; 
        padding-left:20px;
        max-height: 0px;
        overflow: hidden;
        transition:0.3s ease;
    }

    .nav-links .mega-container li{
        margin:0;
    }

    .nav-links .mega-container li Link {
        font-size: 18px;
    }

    /* Hiding the Header text & content in the mega menu */

    .mega-special-container{
        display: none;
    }

    .nav-links .desktop-item{
        display:none;
    }

    .nav-links .mobile-item{
        display: block;
        color:#fff;
        cursor: pointer;
        transition: 0.3s ease;
    }

    .nav-links .mobile-item:hover{
        background:#efeded;
    }

    .mobile-separate{
        display: flex;
        
    }

    .separate1{
        display: inline-flex;
        float:left;

    }

    .separate2{
        display: inline-flex;
        float:right;

    }
     
    .nav-links li .nav-link:hover{
        border-bottom:none !important;      
    }


    .mega-container .mega-content {
        background-color: #010528 ;
        padding: 10px 0 0 10px;
        flex-direction: column;
    
    }

    .mega-container .mega-content .row{
        width: 100%;
        margin-bottom: 10px;  
    }
    
    .mega-content .row .mega-links{
        padding-left: 15px;
           
    }

    .row .mega-links li{
        margin:0;

    }

     /* Hiding scrollbar for Chrome, Safari and Opera */
     .nav-links::-webkit-scrollbar {
        display: none;
    }

    /* Hiding scrollbar for IE, Edge and Firefox */
        .nav-links {
        scrollbar-width: none;  /* Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
    }

    .navbar .btn{
        display: block;
        color:#fff;
    }

    .custom-navbar .btn{
        display: block;
        color:#fff;
    }
    
    .mobile-item:hover .icon-rotate svg {
        transition: 0.2s;
        transform:rotate(180deg) !important;
        -webkit-transform:rotate(180deg) !important;
        -ms-transform:rotate(180deg) !important;     
    }


     /* Extra CSS code ends */
    .nav-links.show{
        left:0%;
        
    }

    .mega-container {
        position: static;
        top:70px;
        width:100%;
        opacity:1;
        visibility: visible;
        padding-right:20px;
        max-height: 0px;
        overflow: hidden;
        transition:0.3s ease;
    }

    .mega-container.open{
        max-height: 100%;
    }

   .toggle-rotate svg {
        transition: 0s;
        transform:rotate(180deg) !important;
        -webkit-transform:rotate(180deg) !important;
        -ms-transform:rotate(180deg) !important;   
    }

    .menu-btn svg{
        color:#fff;
        border:none !important;
    }

    .menu-btn svg:active{
        color:#fff;
        border:none !important;
    }
    /* Extra CSS code ends */
 

}
