*{
  margin:0;
  padding:0;
 
}

.app{
  font-family: "Poppins";
  overflow-x: hidden !important;
  max-width: 100% !important;
}

/* Home page styles */

.videoBg{
  min-width: 100%;
  height: 110vh;
  margin:-10% 0 0 0;
  
}

.videoBg video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;

}

.videoBg-overlay{
  content: '';
  position: absolute;
  width: 100%;
  height: 110vh;
  overflow: hidden;
  background-color: rgba(0,0,0,.7);

}

.caption1{
  position:absolute;
  width: 100%;
  height: 100%;
  top:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation:text-movement 4s 2s infinite ease; 

}


.caption1 h1{
  font-size: 5vw;
  font-weight:900;
  color:white;
 
}

.caption1 h3{
  color:#0ab3d2;
}


@keyframes text-movement{

  0%{
      transform: translateY(20%);
      opacity: 0;
  }
  100%{
      transform: translateY(0);
      opacity: 1;
  }
 
}

.caption2{
  width:100%;
  position:absolute;
  display: flex;
  align-items: start;
  justify-content: space-around;
  margin-top:-12%;

}

@keyframes scrollx {
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(-100%);
  }
  
}

.moving-img img{
  width:60px;
 
}

.scrolldown{
  width: 50px;
  margin-top: 30px;
  animation:fadeInImage 1s ease forwards, dance 3.3s ease-in-out infinite;
}

@keyframes fadeInImage {
  0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}

@keyframes dance {
0% {
    transform: translateY(0);
  }
  
50% {
  transform: translateY(-10px);
}
0% {
  transform: translateY(0);
}  
}

.caption3{
  width:100%;
  position:absolute;
  display: flex;
  align-items: start;
  justify-content: center;

}

/* Hero section ends */

/* Trusted Partner section */

.second-section{
  background: linear-gradient(to top, #00051e,  #010528);
  padding:5% 0;
  color:#fff;
}

.handshake{
  width:50%;
  height: auto;
}

.handshake2{
  width:40%;
  height: auto;
  z-index: 1;
  margin-left: -8%;
 
}

.trusted-partner h3{
  font-weight: bold;
}

.second-section-text h5{
  font-weight: bold;
}

.second-section-text p{
  font-size: 14px;

}

.second-section-icon{
  font-size: 30px;
  text-align: center;
}

/* Trusted Partner section ends */

/* Services showcase */
.showcase-cont{
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.panel{
  width: 50px;
  height: 80vh;
  margin: 10px;
  border-radius: 20px;
  flex:1;
  background: no-repeat scroll center;
  background-size: cover;
  transition:all 0.5s linear;
  position: relative;
}

.panel h6{
  position: absolute;
  left:0;
  bottom:100px;
  transform: rotate(-90deg);
  transition:all 0.5s linear;
}
.panel:hover{
  flex:4;
  
}

.panel:hover h6{
  transform: rotate(0deg);
  left:15px;

}

.reveal{
  transition:all 0.8s linear;
  display: none;
  
  
}

.panel:hover .reveal{
  display: block;
  position: absolute;
  left:15px;
  bottom:50px;
 
}

#panel-1{
  background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("assets/images/service10.jpg");
}
#panel-2{
  background-image:linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("assets/images/nserv3.jpg");
}
#panel-3{
  background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("assets/images/service1.jpg");
}
#panel-4{
  background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("assets/images/service3.jpg");
}
#panel-5{
  background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("assets/images/service6.jpg");
}
#panel-6{
  background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("assets/images/service7.jpg");
}
#panel-7{
  background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("assets/images/service2.jpg");
}
#panel-8{
  background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("assets/images/service5.jpg");
}
#panel-9{
  background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("assets/images/service4.jpg");
}


/* Services showcase ends */

/* Location section */
.location-section{
  background-color: #010528;
}

.first-cont{
  background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url("assets/images/sa.jpg");
  background-size:cover;
  transition:1s ease;

}
.second-cont{
  background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url("assets/images/india2.jpg");
  background-size:cover;
  transition:1s ease;

}
.middle-cont{
  background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url("assets/images/sa2.jpg");
  background-size:cover;
  transition:1s ease;

}

.first-cont:hover{
  transform: scale(1.1);
}

.second-cont:hover{
  transform: scale(1.1);
}

.middle-cont:hover{
  transform: scale(1.1);
}

.cont-inner{
  font-size: 12px;
}

/* Location section ends*/


/* Services page */
.banner-bg img{
  width:100%;
  margin:-10% 0 0 0;
  height: 100vh;
  object-fit: cover;
  
}

.banner-bg-content h1{
  font-size: 70px;
  font-weight: bold;
  color:white;
}

.bg-content{
  position:absolute;
  height: 100%;
  top:0;
  display:flex; 
  align-items: center;
  justify-content: flex-start;
  color:#fff;
}

.text p{
  text-align: justify;
}

.section-bg1{
  background-image: url(assets/images/bg3-n.jpg);
  background-size: cover;
  color:#fff;
}

.section-bg2{
  background-color:  #050B3A;
  color:#fff;
}

.service-img{
  width: 350px;
  transition:1s;
}

.service-img:hover{
  transform: scale(1.1);
}

/* Services page ends*/

/* About page*/

.about-img{
  width: 500px;
  transition:1s;

}

.about-img:hover{
  transform: scale(1.1);
}

.about-heading{
  color:#08baf5;
}

.offer{
  text-align: justify;
  font-size: 15px !important;
}

.offer-icon{
  color:#08baf5;
}


.dash{
  width: 60px;
  height: 2px;
  background-color: #08baf5;
  display: inline-block;
}

.contact-btn{
  background-color: #08baf5;
  border-radius:10px;
  border:none;
  padding:15px !important;
}

.vision-cont{
  background-color: #050b3a84;
  transition:1s ease;
}

.vision-cont:hover{
  background-color:#111536e6;

}

.vision-icon{
  font-size: 32px;
  color: #08baf5;
}


/* About page ends*/

/* Contact page */
form input,textarea{
  border:none;
  border-bottom:1px solid #cccccc; 
  padding:10px;
  background:transparent;
  color:#fff;
  
  
}

form {
  display:flex;
  flex-direction:column;
 
}

.input-full{
  width: 100%;

}

::placeholder{
  color: #fff;
  
} input[type = checkbox]{
  display: inline-block;
}



/* Contact page ends */

/* Careers Page */

.flip-cont{
  transition:1s ease;
}

.flip-cont2{
  transition:1s ease-in-out;
}

.flip-card {
  position: relative;
  width: 100%;
  height: 220px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 3px 4px 8px rgba(0,0,0,0.2);
}

.flip-card2 {
  position: relative;
  width: 100%;
  min-height: 600px !important;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 3px 4px 8px rgba(0,0,0,0.2);
}

flip-card p{
  font-size: 13px;
}

flip-card2 p{
  font-size: 13px;
}

.flip-card:hover {
  transform: rotateY(180deg);
}

.flip-card2:hover {
  transform: rotateX(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 16px;
  
}

.flip-card-front2, .flip-card-back2 {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 16px;
  
}

.flip-card-front{
  background-color: #050b3a84;
}

.flip-card-front2{
  background-color: transparent;
}

.flip-card-back {
  background-color: #fff;
  color: #000;
  transform: rotateY(180deg);

}

.flip-card-back2 {
  background-color: #fff;
  color: #000;
  transform: rotateX(180deg);

}

.apply{
  border-radius:10px;
  border:none;
  padding:0 15px;
}

.see-req{
  background:transparent;
  border-radius:10px;
  border:1px solid #08baf5;
  padding:0 15px;
  color:#08baf5;
  font-size:13px;
}

.new-apply{
  background-color:#08baf5;
  border-radius:10px;
  border:1px solid #08baf5;
  padding:0 15px;
  font-size:13px;
}
/* Careers Page ends */

/* Footer */
.FooterBg{
  background-color: #010528;
  color:#fff;
}

.FooterLogo{
  width: 150px !important;
}

.link-text{
  color: #fff;
  text-decoration: none;

}
.read-more{
  color: #08baf5 !important;
  text-decoration: none;
}

a.link-text:hover{
  color: #08baf5 !important;
}

.to-top{
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-radius: 50%;
  color: #08baf5;
  text-align: center;
  z-index: 10;

}

.whatsapp{
  position: fixed;
  bottom: 50px;
  left: 50px;
  color: #07ca6f;
  z-index: 10;
  animation:dance 3.3s ease-in-out infinite;

}

/* Footer ends */





/* Media Query */
@media screen and (max-width:767px){
  .app{
    overflow-x: hidden;
    max-width: 100%;
  }
  .videoBg{
    height: 90vh;
    
  }
  
  .videoBg-overlay{
    height: 90vh;
  
  }

  .banner-bg{
    height: 90vh;

  }

  .banner-bg-content h1{
    font-size: 35px;
    
  }

  .caption1 h1{
    font-size: 7vw;
   
   
  }

  .caption1 h3{
    font-size: 16px;
  }
  
  .caption2{
    margin-top:-30%;
   
  }

  .moving-img img{
    width:40px;
   
  }
  .scrolldown{
    width: 40px;

  }


  .showcase-cont{
    flex-direction: column;

  }

  .panel{
    border-radius: 10px;
   
  }
  
  .panel h6{
    transform: rotate(0deg);
    bottom:0;
    left:15px;
    font-size:13px;

  }

  .know-more{
    font-size: 12px;
  }

  .cont-inner{
    font-size: 11px;
  }

  .flip-card2 {
    min-height: 900px !important;
    
  }
  

  .to-top{  
    bottom: 30px;
    right: 20px;
  
  }
  
  .whatsapp{
    bottom: 30px;
    left: 20px;
  
  }


}


/* Media Query tab */
@media screen and (max-width:991px){
  .flip-card2 {
    min-height: 800px !important;
    
  }

  .caption2{
    margin-top:-18%;
 
  }

  .scrolldown{
    margin-top: 0px;
    
  }
  

}



  

 




/* Password: @JaggulasConsulting1 for business; @JaggulasConsulting11 for admin*/
